<div class="offer-card">
  <a href="/offres/{{bien.slug}}"></a>
  <div class="header px-3 py-2">
    <h4 class="text-white">{{ bien.ville + ' (' + bien.departement + ')' }}</h4>
    <p class="text-white">{{ titre }} </p>
    <h5>{{ bien.prix }} €</h5>
  </div>
  <div class="offer-image">
    <div class="background" [ngStyle]="{'background-image':'url(' + (bien.images[0]?.file ? bien.images[0]?.file : '../../../assets/toRemove/img-exemple.jpg') + ')'}"></div>
    <div class="overlay">
      <i class="fa-solid fa-plus"></i>
    </div>
  </div>
  <div class="p-3">
    <div>
      <ul class="p-0">
        <li>
          Réf: {{ bien.reference }}
        </li>
        <li>
          {{ bien.nbPieces }}
        </li>
        <li>
          {{bien.surfaceHabitable }} m²
        </li>
      </ul>
    </div>
    <div class="row d-flex px-3 mb-3 offer-body">
      <div class="col-lg-6 ps-0">
        <div class="d-flex align-items-center justify-content-center border-end  pe-4 mb-3 mb-lg-0">
          <i class="fa-regular fa-circle-user fa-2x text-purple"></i>
          <div class="ms-3">
            <p class="m-0 text-purple">{{ bien.negociateur }}</p>
            <!-- <p class="m-0">Negociateur</p> -->
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <p class="m-0 ">{{bien.phone}}</p>
        <p class="m-0 ">{{bien.mail}}</p>
      </div>
    </div>
  </div>
</div>
